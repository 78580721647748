import { useMemo, useState } from "react";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { BiTrash } from "react-icons/bi";
import { IconEdit, IconFileTypeDoc, IconLock } from "@tabler/icons-react";
import dayjs from "dayjs";

import { AgeusersAPI } from "src/http/server-apis";
import TeamAddEditDialog from "./ParticipantAddEditDialog";
import { getImageUrl } from "src/utils";
import TableActionIcon from "src/components/common/table/TableActionIcon";
import { DELETE_ERROR_MESSAGE, DELETE_MESSAGE } from "src/utils/constants";
import DataTable from "src/components/common/table/DataTable";
import DeleteDialogBox from "src/components/common/DeleteDialog";
import PlayerImg from "src/assets/images/playerGroup.png";

interface ITeamListProps {
  addOpen: boolean;
  addClose: () => void;
  searchText: string;
  RightID?: any;
  listData: any;
  usertype?: string;
  TeamName: string;
  reload: () => void;
}

const AgencyUserList: React.FC<ITeamListProps> = (props) => {
  const {
    addOpen,
    addClose,
    searchText,
    listData,
    reload,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState({
    value: null,
    open: false,
  });


  const [deleteData, setDeleteData] = useState({
    value: {},
    open: false,
  });

  const deleteBoxClose = () => setDeleteData({ open: false, value: {} });

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "PreferredName",
        size: 40,
        cell: (cell: any) => (
          <Box className="flex items-center">
            <Avatar
              sx={{
                backgroundColor: "white",
                border: "1px solid #d9d9d9",
              }}
            >
              <img
                src={getImageUrl("profile", cell.row.original.Photo, cell.row.original.Gender)}
                alt={cell.row.original.Photo}
                className="object-cover w-full"
              />
            </Avatar>
            <div className="flex flex-col">
              <Typography fontSize={"small"} textAlign="left" pl={2}>
                {cell.getValue()} {cell.row.original.IsCaptain ? '(C)' : ""}
              </Typography>

              <Typography color="secondary.light" variant="body2" pl={2}>
                {cell.row.original.Gender} ||{" "}
                {dayjs().diff(cell.row.original.DOB, "year")} years
              </Typography>
            </div>
          </Box>
        ),
      },
      {
        header: "Accreditation Number",
        accessorKey: "ACRNo",
        size: 10,
        cell: (cell: any) => (
          <Typography fontSize={"small"} textAlign="center">
            {cell.getValue()}
          </Typography>
        ),
      },

      {
        header: "Action",
        size: 10,
        cell: (cell: any) => (
          <Box sx={{ display: "flex", justifyContent: "center" }}>

            {cell.row.original?.Locked ? (
              <Tooltip title="Sorry, You are locked." arrow>
                <TableActionIcon>
                  <IconLock size={20} className="blueIcon-hover" />
                </TableActionIcon>
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Document" arrow>
                  <TableActionIcon
                    onClick={() => {
                      window.open(getImageUrl('agencydocuments', cell.row.original.SupportDocuments), '_blank');
                    }
                    }
                  >
                    <IconFileTypeDoc size={20} className="blueIcon-hover" />
                  </TableActionIcon>
                </Tooltip>
                <Tooltip title="Edit" arrow>
                  <TableActionIcon
                    onClick={() =>
                      setEdit({ open: true, value: cell.row.original })
                    }
                  >
                    <IconEdit size={20} className="blueIcon-hover" />
                  </TableActionIcon>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <TableActionIcon
                    onClick={() =>
                      setDeleteData({
                        open: true,
                        value: cell.row.original.AgeUserID,
                      })
                    }
                  >
                    <BiTrash
                      size={20}
                      className="group-hover:text-[#fa896b] transition duration-150 group-hover:duration-500"
                    />
                  </TableActionIcon>
                </Tooltip>
              </>

            )}

          </Box>
        ),
      },
    ],
    []
  );

  const onDelete = async () => {
    try {
      const AgeUserID = deleteData?.value;
      const res = await AgeusersAPI("delete", {
        params: AgeUserID,
        data: {
          Deleted: 1,
        },
      });
      if (res?.status === 200) {
        enqueueSnackbar(DELETE_MESSAGE, {
          variant: "success",
        });
        reload();
      }
    } catch (err: any) {
      enqueueSnackbar(DELETE_ERROR_MESSAGE, { variant: "error" });
    }
    deleteBoxClose();
  };

  return (
    <>
      {listData?.length === 0 ? (
        <Box className="flex flex-col items-center">
          <img src={PlayerImg} alt="player" className="w-[10%]" />
          <Typography variant="h6">Your Team Awaits</Typography>
        </Box>
      ) : (
        <DataTable
          loading={false}
          showNotFound={false}
          data={listData || []}
          columns={columns}
          tablePagination={true}
          searchText={searchText}
        />
      )}
      <DeleteDialogBox
        open={deleteData?.open}
        onClickClose={deleteBoxClose}
        onClickOk={onDelete}
      />
      {edit.open && (
        <TeamAddEditDialog
          open={edit.open}
          close={() => setEdit({ open: false, value: null })}
          user={edit.value}
          reload={reload}
          variant="edit"
        />
      )}

      {addOpen && (
        <TeamAddEditDialog
          open={addOpen}
          close={addClose}
          user={null}
          reload={reload}
          variant="add"
        />
      )}

    </>
  );
};

export default AgencyUserList;
