import {
  Avatar,
  Box,
  Card,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { AgenciesAPI } from "src/http/server-apis";
import Spinner from "src/views/spinner/Spinner";
import PageContainer from "src/components/common/components/PageContainer";
import Breadcrumb from "src/layouts/full/shared/breadcrumb/Breadcrumb";
import Toolbar from "src/components/common/Toolbar";
import AgencyUserList from "src/components/apps/agency/registration/AgencyUserList";
import { RootState } from "src/store/Store";
import AlertBreadcrumb from "src/components/common/components/AlertBreadcrumb";
import DeleteDialogBox from "src/components/common/DeleteDialog";
import { getImageUrl } from "src/utils";
import { Link } from "react-router-dom";
import { BiMobile, BiUser } from "react-icons/bi";
import { MobileScreenShare } from "@mui/icons-material";

const AgencyUserRegistration = () => {
  const { UserID } = useSelector((state: RootState) => state?.user);
  const theme: any = useTheme();
  const [data, setData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [add, setAdd] = useState(false);
  const [isLockDialog, setIsLockDialog] = useState(false);

  const { isLoading, isFetching, refetch } = useQuery(
    ["get-all-agensies", UserID],
    () =>
      AgenciesAPI("get", {
        postfix: `/${UserID}`,
      }),
    {
      onSuccess(data: { status: number; data: any }) {
        if (data?.status === 200) {
          setData(data.data || []);

        }
      },
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 5,
      // refetchInterval: false,
    }
  );


  const onLockHandler = async () => {
    try {
      const response = await AgenciesAPI("post", {
        postfix: `/locked`,
        data: {
          AgencyID: UserID,
          LockedType: "All",
          Locked: 1
        },
      });
      if (response?.status === 200 && response.data?.status === "success") {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        refetch();
        setIsLockDialog(false);
      } else {
        enqueueSnackbar(response?.data?.message || "Error saving data", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Error submitting data", { variant: "error" });
    }
  };

  if (isLoading || isFetching) return <Spinner />;

  return (
    <PageContainer title={data?.CompanyName || ""}>
      <Breadcrumb
        title={`Users`}
      />

      {/* {fedration details} */}
      <div className="shadow-sm bg-white rounded-lg p-5  my-5 bg-[url('./assets/images/white_wave.png')]  !bg-no-repeat  bg-posRight">
        <div className="grid  grid-cols-3 gap-5 ">
          <div className="col-span-2 pr-5">
            <Typography variant="h5" className="flex ">
              {data?.Organisation || "-"}
            </Typography>
            <ul className="flex flex-col gap-4 mt-5 w-full">
              <li className="flex  gap-5 ">
                <div className="flex gap-2">
                  <BiUser className="mt-1 text-gray-300" />
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.secondary.main,
                      }}
                    >
                      {data?.ContactName || "-"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.gray.dark,
                      }}
                    >
                      Contact Name
                    </Typography>
                  </div>
                </div>

                <div className="flex gap-2  ">
                  <BiMobile className="mt-1 text-gray-300" />
                  <div className="">
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.secondary.main,
                      }}
                    >
                      {data?.EmailID || "-"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.gray.dark,
                      }}
                    >
                      Email
                    </Typography>
                  </div>
                </div>
              </li>

              <li className="flex gap-2 ">
                <MobileScreenShare className="mt-1 text-gray-300" />
                <div className="flex-1 flex flex-col">
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.secondary.main,
                    }}
                  >
                    {data?.MobileNo || "-"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.gray.dark,
                    }}
                  >
                    Mobile No.
                  </Typography>
                </div>
              </li>
            </ul>
          </div>
          <div className="">

            <Avatar
              src={
                getImageUrl(
                  "profile",
                  data?.Photo
                )

              }
              alt="Orginization Logo"
              className="!rounded-lg !w-[120px] !h-[120px]"
            />

          </div>
        </div>
      </div>
      <AlertBreadcrumb
        success={data?.Locked ? true : false}
        title={
          data?.Locked
            ? "This section has been submitted and locked. Changes are not permitted anymore."
            : `Once this section is final, click the lock button to submit the data for further processing.`
        }
        btnProps={
          data?.Locked
            ? undefined
            : {
              title: "Confirm & Submit",
              onClick: () => setIsLockDialog(true),
            }
        }
      />
      <Card>

        <Toolbar searchText={searchText} setSearchText={setSearchText} onSearchProps={true} buttonProps={data?.AgencyUsers?.length < +data?.Quota ? { title: 'Add', onClick: () => setAdd(true) } : undefined} />

        <AgencyUserList
          addOpen={add}
          addClose={() => setAdd(false)}
          reload={refetch}
          searchText={searchText}
          listData={data?.AgencyUsers || []}
          TeamName={data?.TeamName}
        />

      </Card>

      <DeleteDialogBox
        open={isLockDialog}
        onClickClose={() => setIsLockDialog(false)}
        onClickOk={onLockHandler}
        headerTitle="Confirm and Submit"
        title={`
          Please ensure that all records are accurate and final. Once submitted, any changes will NOT be permitted.`}
        confirmButtonTitle="Confirm & Lock"
        cancelButtonTitle="Cancel"
      />

    </PageContainer >
  );
};

export default AgencyUserRegistration;
