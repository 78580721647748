import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

import AsyncAutocomplete from "src/components/common/forms/input-elements/AsyncAutoComplete";
import CustomDatePicker from "src/components/common/forms/input-elements/CustomDatePicker";
import CustomTextField from "src/components/common/forms/input-elements/CustomTextField";
import { formInuputStep1 } from "./playerData";

import {
  designationData,
  genderData,
  getFileExtension,
  participantTypeData,
} from "src/utils";
import FileUploader from "src/components/common/uiElements/FileUploader";
import ShopAvtar from "src/components/common/image/ShopAvtar";
import CustomTextArea from "src/components/common/forms/input-elements/CustomTextArea";
import { MdOutlineUploadFile } from "react-icons/md";
import fileUpload from "src/assets/images/file_upload.png";

export default function UserAgencyBasicForm(props: any) {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    touched,
    setFieldValue,
    image,
    setImage,
  } = props;

  function options(name: string) {
    const optionDataMap: Record<string, any> = {
      AcrName: participantTypeData(values),
      Designation: designationData,
      Gender: genderData,
    };

    return optionDataMap[name] || [];
  }

  function loading(name: string) {
    const loadingMap: Record<string, boolean> = {};

    return loadingMap[name] || false;
  }

  function objFilter(name: string) {
    const filterMap: Record<string, { title: string; value: string }> = {};

    return filterMap[name] || { title: "label", value: "label" };
  }
  return (
    <div>
      <div className="grid grid-cols-6 gap-2">
        {formInuputStep1()?.map((x) => {
          const { className, type, name, ...rest } = x;
          return (
            <div className={className} key={name}>
              {type === "text" ? (
                <CustomTextField
                  name={name}
                  type={type}
                  fullWidth
                  value={values[name] || ""}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  error={!!(errors[name] && touched[name])}
                  helperText={touched[name] ? errors[name] : ""}
                  onBlur={handleBlur}
                  {...rest}
                />
              ) : type === "password" ? (
                <CustomTextField
                  name={name}
                  type={type}
                  fullWidth
                  value={values[name] || ""}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  error={!!(errors[name] && touched[name])}
                  helperText={touched[name] ? errors[name] : ""}
                  onBlur={handleBlur}
                  {...rest}
                />
              ) : type === "number" ? (
                <NumericFormat
                  min="1"
                  customInput={CustomTextField}
                  name={name}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  displayType="input"
                  valueIsNumericString
                  onChange={handleChange}
                  fullWidth
                  value={values[name] || ""}
                  error={!!(errors[name] && touched[name])}
                  helperText={touched[name] ? errors[name] : ""}
                  onBlur={handleBlur}
                  {...rest}
                />
              ) : type === "date" ? (
                <CustomDatePicker
                  name={name}
                  value={values[name] || ""}
                  maxDate={dayjs().subtract(16, "year")}
                  handleChange={(val) => setFieldValue(name, val)}
                  TextInputProps={{
                    error: !!(errors[name] && touched[name]),
                    helperText: touched[name] ? errors[name] : "",
                    onBlur: handleBlur,
                  }}
                  {...rest}
                />
              ) : type === "textarea" ? (
                <CustomTextArea
                  name={name}
                  value={values[name] || ""}
                  onChange={handleChange}
                  error={!!(errors[name] && touched[name])}
                  helperText={touched[name] ? errors[name] : ""}
                  onBlur={handleBlur}
                  {...rest}
                />
              ) : (
                <AsyncAutocomplete
                  id={name}
                  loading={loading(name)}
                  options={options(name) || []}
                  objFilter={objFilter(name)}
                  value={values[name]}
                  TextInputProps={{
                    error: !!(errors[name] && touched[name]),
                    helperText: touched[name] ? errors[name] : "",
                    onBlur: handleBlur,
                  }}
                  onChangeOption={(value) => {
                    setFieldValue(name, `${value}`);
                  }}
                  {...rest}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-3 gap-5">
        <div className="">
          <Typography
            variant="subtitle1"
            className="!mt-2 !mb-2"
            fontWeight={600}
          >
            Profile
          </Typography>
          <div className="grid grid-cols-1 gap-2 py-2">
            <ShopAvtar
              src={image?.Photo || values["Photo"]}
              type="profile"
              download={values["Photo"] || false}
              imgRectangle
              sx={{
                width: "100%",
                height: 120,
                objectFit: "contain",
                "& .MuiAvatar-img": {
                  objectFit: "contain",
                },
              }}
              variant="rounded"
              crop={{
                image: image?.Photo,
                handleChange: (file: any) =>
                  setImage({ ...image, Photo: file }),
              }}
            />
            <FileUploader
              handleChange={(file: any) => {
                setImage({ ...image, Photo: file });
              }}
            />
          </div>
        </div>

        <div className="">
          <Typography
            variant="subtitle1"
            className="!mt-2 !mb-2"
            fontWeight={600}
          >
            Document
          </Typography>
          <div className="grid grid-cols-1 gap-2 py-2">
            {(image?.SupportDocuments?.name || values["SupportDocuments"]) &&
            getFileExtension(
              image?.SupportDocuments?.name || values["SupportDocuments"]
            ) === "pdf" ? (
              <Box className="h-[13vh] flex items-center justify-center border shadow-md flex-col gap-2">
                <img src={fileUpload} alt="uploadfile" className="w-[20%]" />
                <Typography variant="subtitle1" className="!font-semibold">
                  File Uploaded
                </Typography>
              </Box>
            ) : getFileExtension(
                image?.SupportDocuments?.name || values["SupportDocuments"]
              ) ? (
              <ShopAvtar
                src={image?.SupportDocuments || values["SupportDocuments"]}
                type="profile"
                download={values["SupportDocuments"] || false}
                imgRectangle
                sx={{
                  width: "100%",
                  height: 120,
                  objectFit: "contain",
                  "& .MuiAvatar-img": {
                    objectFit: "contain",
                  },
                }}
                variant="rounded"
              />
            ) : (
              <Box className="h-[13vh] flex items-center justify-center border shadow-md">
                <MdOutlineUploadFile size={60} className="text-gray-100" />
              </Box>
            )}

            <FileUploader
              handleChange={(file: any) => {
                setImage({ ...image, SupportDocuments: file });
              }}
              types={["pdf", "JPEG", "PNG", "JPG", "WEBP"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
